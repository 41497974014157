import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Close = (props: Props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.21875 5.78125L5.78125 7.21875L14.5625 16L5.78125 24.7812L7.21875 26.2188L16 17.4375L24.7812 26.2188L26.2188 24.7812L17.4375 16L26.2188 7.21875L24.7812 5.78125L16 14.5625L7.21875 5.78125Z" />
  </svg>
);

export default Icons8Close;
