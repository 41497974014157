import AppAlertDialog from "@/src/components/custom/alert-dialog";
import { useListener } from "@/src/hooks/useListener";
import { MODAL_EVENTS } from "@/src/lib/constants";
import { useState } from "react";

interface Props {}
const TermsAndConditionsModal: React.FC<Props> = ({}) => {
  const [show, setShow] = useState(false);
  useListener(MODAL_EVENTS.TERMS_AND_CONDITIONS, () => setShow(!show));

  const handleToggle = (state: boolean) => {
    setShow(!show);
  };

  return (
    <AppAlertDialog
      open={show}
      toggle={handleToggle}
      title="Terms & Conditions"
      content={
        <div className="!text-left">
          <h3 className="font-bold text-lg text-dark-primary">Introduction</h3>
          <p className="text-dark-primary">
            MyBlankSpace Limited ("MyBlankSpace") is a limited liability company
            incorporated under the laws of Nigeria. Myblankspace is the
            commercial short-let platform that allows Individuals, Businesses
            and NGOs the flexibility to rent a on a short term contract at a
            reasonable price.
          </p>
          <br />
          <h3 className="font-bold text-lg text-dark-primary">Services Agreement</h3>
          <p className="text-dark-primary">
            This Services Agreement ("SA" or "this Agreement") is a legal and
            binding agreement between MyBlankSpace and you, ("you", "the
            Landlord") who has set up a MyBlankSpace account to access the
            Services offered by MyBlankSpace. It provides a general description
            of the Services that MyBlankSpace may provide to our Landlords and
            what is expected of our Landlords.
          </p>
          <br />
          <p className="text-dark-primary">
            1. Listing Creation: - Hosts must provide accurate and complete
            information about their listing, including its location, amenities,
            pricing, availability, and any commercial space rules. - Hosts are
            responsible for keeping their listing information up-to-date.
            <br /> <br />
            2. Compliance with Laws: - Hosts must comply with all applicable
            laws, including zoning regulations, rental laws, and health and
            safety standards. - Hosts are responsible for obtaining any
            necessary permits or licenses.
            <br /> <br />
            3.Exclusivity of Listings. - Host agree that any property listed on
            MyBlankSpace shall not be listed on any other short- term rental
            market platform or similar service for the duration of its listing
            on MyBlankSpace. Partners acknowledge and agree that this
            exclusivity requirement is a material term of this agreement, and
            any violation of this clause may result in immediate termination of
            the listing and potential suspension or termination of the Host’s
            account.
            <br /> <br />
            4. Booking and Cancellations: - MyBlankSpace facilitates bookings
            and payments between clients and hosts. - Hosts set their own
            cancellation policies, which must be clearly communicated to clients
            (renter).
            <br /> <br />
            5. Fees and Payouts: - MyBlankSpace charges 10% booking fee for each
            booking, which is deducted from the payout to the host. - Payouts to
            hosts are made at month end and typically occur after the client
            booking has been fulfilled by host.
            <br /> <br />
            6. Host Responsibilities: - Hosts are expected to maintain their
            property in a clean and safe condition. - Hosts must address any
            issues or complaints from clients promptly and professionally.
            <br /> <br />
            7. Unsuccessful Listing Applications: - MyBlankSpace reserves right
            to reject listing application that fails to meet quality control
            standards. - Listings are expected to fit for purpose and safe for
            client use.
            <br /> <br />
            7. Insurance and Liability: - Hosts should have their own insurance
            coverage to protect against property damage or liability claims.
            <br /> <br />
            8. Prohibited Activities: - Hosts must not engage in any activities
            that violate MyBlankSpace's policies, such as discrimination, fraud,
            or creating fake listings. - Listings must not contain any content
            that is offensive, harmful, or illegal.
            <br /> <br />
            9. Termination: - MyBlankSpace reserves the right to terminate a
            host’s account or remove listings that violate its terms and
            policies. - Hosts can also deactivate their listing or terminate
            their account after a weeks notice to Myblankspace via email
            addressed to shortlet@myblankspace.com.
            <br /> <br />
            10. Dispute Resolution: - MyBlankSpace provides a process for
            resolving disputes between clients and hosts, which may involve
            mediation or arbitration.
            <br /> <br />
            11. Force Majeure - Neither party will be liable for any delays in
            processing or other non-performance caused by telecommunications,
            utility failures, or equipment failures; industrial labour strike,
            riots, war, or terrorist attacks; non performance of our vendors or
            supplier, epidemic, pandemic, fires or acts of nature; action of
            government entities or any other event over which the respective
            party has no reasonable control. - However, nothing in this section
            will affect or excuse your liabilities or your obligation to pay
            Fees, Fines, Disputes, refunds, Reversals, or Returns under this
            Agreement.
            <br /> <br />
            12. Right to Amend - We have the right to change or add to the terms
            of this Agreement at any time and to change, delete, discontinue, or
            impose conditions on use of the Services by posting such changes on
            our website. We will provide you with Notice of any changes through
            the Dashboard, via email, or through other reasonable means. If you
            are an existing MyBlankSpace user, the changes will come into effect
            on the date we specify in the Notice, and your use of the Services,
            API, or Data after a change has taken effect, constitutes your
            acceptance of the terms of the modified Agreement. You can access a
            copy of the current terms of this Agreement on our website or your
            Dashboard at any time.
            <br /> <br />
            13. Termination -
            <br /> <br />
            13.1 This Agreement is effective upon the date you first access or
            use the Services and continues until terminated by you or
            MyBlankSpace. You may terminate this Agreement by closing your
            MyBlankSpace Account after one week notice and ceasing to use the
            Service. If you use the Services again or register for another
            MyBlankSpace Account, you are consenting to this Agreement. - We may
            terminate this Agreement or close your MyBlankSpace Account at any
            time for any reason (including, without limitation, for any activity
            that may create harm or loss to the goodwill of clients) by
            providing you advance Notice (which shall not be less than 24
            hours). We may suspend your MyBlankSpace Account and your ability to
            access the Services, or terminate this Agreement, on immediate
            Notice if: - a. we determine in our sole discretion that you are
            ineligible for the Services because of significant fraud or any
            other risks associated with your MyBlankSpace Account; - b. you use
            the Services in a prohibited manner or otherwise do not comply with
            any of the - c. we receive a regulatory directive to do so;
            <br /> <br />
            13.2 Effect of Termination: - a. Termination of this Agreement or
            your MyBlankSpace Account does not immediately relieve you of
            obligations incurred by you under this Agreement. Upon termination,
            you agree to (i) complete all pending Transactions, (ii) stop
            accepting new Transactions, and (iii) immediately remove all
            MyBlankSpace logos from your commercial property. - Your continued
            or renewed use of the Services after all pending Transactions have
            been processed serves to renew your consent to the terms of this
            Agreement. If you terminate this Agreement, we will liaise with our
            Acquirers to pay out any remaining funds owed to you in accordance
            with the provisions of this Agreement.
            <br /> <br />
            14. Employing MyBlankSpace Employees - You shall not offer any
            employment to any employee of MyBlankSpace for a period of six (6)
            months, after the employee has left the employ of MyBlankSpace,
            without the prior written consent of MyBlankSpace.
            <br /> <br />
            15. Anti Bribery Provisions and Sanctions - You represent and
            undertake to MyBlankSpace that: - a. you will comply with
            Anti-Corruptions Laws; - b. you shall not undertake any act or
            engage (directly or in agreement with others or any third party) in
            any activities directly or indirectly with respect to any matters,
            either in private or public dealings which would violate any
            Anti-Corruption Laws or be considered as being unethical,
            fraudulent, illegal or improper;
            <br /> <br />
            16.Language - The parties hereby acknowledge that they have required
            this Agreement and all related documents to be in the English
            language.
          </p>
        </div>
      }
      className="max-w-[600px]"
      contentClassName="max-h-[500px] overflow-auto"
      type="info"
      actionText="Continue"
    />
  );
};
export default TermsAndConditionsModal;
