import AppAlertDialog from "@/src/components/custom/alert-dialog";
import { useListener } from "@/src/hooks/useListener";
import { MODAL_EVENTS } from "@/src/lib/constants";
import { useState } from "react";

interface Props {}
const ContactUsModal: React.FC<Props> = ({}) => {
  const [show, setShow] = useState(false);
  useListener(MODAL_EVENTS.CONTACT_US, () => setShow(!show));

  const handleToggle = (state: boolean) => {
    setShow(!show);
  };

  return (
    <AppAlertDialog
      open={show}
      toggle={handleToggle}
      title="Contact Us"
      content={
        <div className="!text-left">
          <h3 className="font-bold text-base sm:text-lg text-dark-primary">Email:</h3>
          <a
            href="mailto:shortlet@myblankspace.com"
            className="text-dark-primary"
          >
            shortlet@myblankspace.com ↗
          </a>
          <br />
          <br />
          <h3 className="font-bold text-base sm:text-lg text-dark-primary">Whatsapp:</h3>
          <a href="https://wa.me/2349097664317" className="text-dark-primary">
            +234 909 766 4317 ↗
          </a>
          <br />
          <br />
          <h3 className="font-bold text-base sm:text-lg text-dark-primary">Whatsapp:</h3>
          <a href="https://wa.me/447860851577" className="text-dark-primary">
            +44 786 085 1577 ↗
          </a>
          <br />
          <br />
        </div>
      }
      className="max-w-[600px]"
      contentClassName="max-h-[500px] overflow-auto"
      type="info"
      actionText="Continue"
    />
  );
};
export default ContactUsModal;
