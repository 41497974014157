import { request } from "../hooks/useRequest";

export function OauthSignIn(params: {
  access_token: string;
  provider: "google" | "facebook";
}) {
  return request("users/oauth", "post", { data: params });
}

export function UserSignIn(params: {
  email: string;
  password: string;
  // as_booker?: boolean;
  role:'booker'|'landlord'
}) {
  return request("users/signin", "post", { data: params });
}

export function AdminSignIn(params: { email: string; password: string }) {
  return request("users/admins/signin", "post", { data: params });
}

export function LandlordSignUp(params: {
  full_name: string;
  agency_name: string;
  is_subletting: boolean;
  email: string;
  phone: string;
  password: string;
}) {
  return request("users/landlord", "post", { data: params });
}

export function BookerSignUp(params: {
  full_name: string;
  email: string;
  password: string;
}) {
  return request("users/booker", "post", { data: params });
}

export function SendVerificationCode() {
  return request("users/send-verification", "get");
}

export function VerifyCode(data: { type: "email" | "phone"; code: string }) {
  return request("users/verify-code", "post", { data });
}
export function SubscribeToNewsletter(data: { email: string }) {
  return request("users/subscribe-newsletter", "post", { data });
}

export function GetLandlord() {
  return request("users/landlord", "get");
}

export function GetBooker() {
  return request("users/booker", "get");
}

export function SaveListing(data: { listing_id: string }) {
  return request("users/save-listing", "post", { data });
}

export function RemoveListing(data: { listing_id: string }) {
  return request("users/remove-listing", "put", { data });
}
