import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import Icons8Close from "../icons/icons8/icons8Close";
import { cn } from "@/src/lib/utils";

interface Props extends React.PropsWithChildren {
  content: React.ReactNode;
  title: string;
  toggle: (state: boolean) => void;
  onConfirm?: () => void;
  open: boolean;
  className?: string;
  contentClassName?: string;
  type?: "info" | "warning";
  actionText?: string;
}
const AppAlertDialog: React.FC<Props> = ({
  children,
  title,
  content,
  toggle,
  open,
  onConfirm,
  className,
  contentClassName,
  type = "info",
  actionText = "Continue",
}) => {
  return (
    <AlertDialog open={open} onOpenChange={toggle}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent className={cn("bg-[#F6F6F6]", className)}>
        <AlertDialogHeader>
          <AlertDialogTitle
            className={cn(
              "flex items-center justify-between text-sm uppercase",
              { "!text-red-500": type === "warning" }
            )}
          >
            {title}
            <AlertDialogCancel className="border-none p-0 bg-transparent w-6.25">
              <Icons8Close />
            </AlertDialogCancel>
          </AlertDialogTitle>
          <AlertDialogDescription className={cn("mt-3.75",contentClassName)}>
            {content}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="mt-5">
          <AlertDialogAction
            onClick={onConfirm}
            type="button"
            className={cn({ "bg-red-500 hover:bg-red-600": type === "warning" })}
          >
            {actionText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AppAlertDialog;
