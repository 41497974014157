import AppAlertDialog from "@/src/components/custom/alert-dialog";
import { useListener } from "@/src/hooks/useListener";
import { MODAL_EVENTS } from "@/src/lib/constants";
import { useState } from "react";

interface Props {}
const PrivacyPolicyModal: React.FC<Props> = ({}) => {
  const [show, setShow] = useState(false);
  useListener(MODAL_EVENTS.PRIVACY_POLICY, () => setShow(!show));

  const handleToggle = (state: boolean) => {
    setShow(!show);
  };

  return (
    <AppAlertDialog
      open={show}
      toggle={handleToggle}
      title="Privacy Policy"
      content={
        <div className="!text-left">
          <p className="text-dark-primary">
            Our privacy policy of outlines how we manage and protect the
            personal information of users.
          </p>
          <br />
          <p className="text-dark-primary">
            Myblankspace collects personal data such as names, email addresses,
            phone numbers, and payment information to facilitate transactions,
            manage bookings, and improve their services. This data is essential
            for processing payments and establishing agreements between brands
            (renters) and landlords.
          </p>
          <br />
          <p className="text-dark-primary">
            In terms of usage, personal data is also leveraged to enhance the
            user experience on their platform, as well as for marketing
            purposes. Users might receive newsletters or promotional content,
            but they have the option to opt out of these communications at any
            time. Additionally, we use anonymised data for analytical purposes,
            helping us refine our services.
          </p>
          <br />
          <p className="text-dark-primary">
            While we do not sell personal data, we may share it with trusted
            third parties, such as service providers or landlords, to fulfil
            contracts or prevent fraud. Myblankspace
          </p>
          <br />
          <p className="text-dark-primary">
            Security is a priority of ours, as a result we use encryption and
            other protective measures to safeguard personal data from
            unauthorised access. Users also have rights over their data,
            including the ability to request access, make corrections, or delete
            their information. Furthermore, users can withdraw consent for
            marketing communications at any time.
          </p>
        </div>
      }
      className="max-w-[600px]"
      contentClassName="max-h-[500px] overflow-auto"
      type="info"
      actionText="Continue"
    />
  );
};
export default PrivacyPolicyModal;
