import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Right_arrow = (props: Props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.0527 7.89014L15.9541 9.01318L19.2988 12.3579H3.625V13.9204H19.2988L15.9541 17.2651L17.0527 18.3882L22.3018 13.1392L17.0527 7.89014Z" />
  </svg>
);

export default Icons8Right_arrow;
