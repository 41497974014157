import Icons8Right_arrow from "@/src/components/icons/icons8/icons8RightArrow";
import LogoLightFull from "@/src/components/icons/logo-light-full";
import { Button } from "@/src/components/ui/button";
import { ICONS, Input } from "@/src/components/ui/input";
import useScreenSize from "@/src/hooks/useScreenSize";
import AboutUsModal from "./about-us";
import TermsAndConditionsModal from "./terms-and-conditions";
import ContactUsModal from "./contact-us";
import PrivacyPolicyModal from "./pirivacy-policy";
import { emit } from "@/src/hooks/useListener";
import { MODAL_EVENTS } from "@/src/lib/constants";
import { useRequest } from "@/src/hooks/useRequest";
import { SubscribeToNewsletter } from "@/src/apis/users.apis";
import { useRef } from "react";
import { useToast } from "@/src/components/ui/use-toast";
import { useRouter } from "next/navigation";

const Footer = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { width } = useScreenSize();
  const { toast } = useToast();

  const subReq = useRequest(SubscribeToNewsletter);
  const router = useRouter()
  const subscribe = async () => {
    const value = ref.current?.value; // email
    if (
      value &&
      value !== "" &&
      value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      const [res, err] = await subReq.makeRequest({ email: value });
      if (res) {
        toast({
          variant: "default",
          description: "You've subscribed successfully",
        });
      }
      if (err) {
        toast({
          variant: "destructive",
          description: err.message,
        });
      }
    }else
    {
      toast({
        variant: "destructive",
        description: "Please enter a valid email",
      });
    }
  };
  const isSmall = width < 1024;

  return (
    <section className="w-full relative pt-25 min-h-[1000px] overflow-hidden">
      <img
        src="https://storage.googleapis.com/myblankspace-prod/assets/footer-bg.png"
        className="w-full h-full object-cover absolute left-0 right-0 top-0"
        alt=""
      />
      <div className="absolute top-[-400px] right-[-30%]">{illustration}</div>
      <div className="relative">
        <div className="px-5 lg:px-25">
          <p className="text-white tracking-tight text-3xl md:text-4xl lg:text-5xl max-w-[500px]">
            Create your account today & start booking{" "}
            <span className="font-display">Spaces</span> today
          </p>
          <Button
          onClick={()=>router.push('/auth/signup')}
            variant="secondary"
            className="rounded-full bg-white h-10 mt-5 lg:mt-[50px]"
          >
            SIGN UP ↗
          </Button>
        </div>
        <div className="mt-[150px] pt-25 pb-17.5 bg-black bg-opacity-20 px-5 md:px-25 backdrop-blur-lg">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex gap-10 text-sm sm:text-base lg:text-xl tracking-tight">
              <ul className="space-y-2.5">
                {appLinks.map((l, i) => (
                  <li key={i}>
                    <button
                      className="hover:text-opacity-100 text-opacity-70 text-white "
                      onClick={l.onClick}
                    >
                      {l.label}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className="space-y-2.5">
                {socialLinks.map((l, i) => (
                  <li key={i + "s"}>
                    <a
                      className="hover:text-opacity-100 text-opacity-70 text-white "
                      href={l.href}
                      target="_blank"
                    >
                      {l.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-fit md:mt-0 mt-10">
              <Input
                ref={ref}
                inputSize={isSmall ? "md" : "lg"}
                prefixIcon={ICONS.EMAIL}
                className="w-[250px] lg:w-[350px] text-white h-fit placeholder:text-[rgba(255,255,255,0.6)]"
                placeholder="Enter your Email"
                iconsClassName="fill-white"
              ></Input>
              <div className="w-full flex items-center mt-5 justify-between">
                <p className="text-white md:text-xl">
                  Join our <span className="font-display">Newsletter</span>
                </p>
                <Button
                  onClick={subscribe}
                  className="w-7.5 h-7.5 md:w-10 md:h-10 bg-white rounded-full p-0"
                >
                  <Icons8Right_arrow className="fill-dark-primary " />
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-25 lg:mt-[150px] flex md:flex-row flex-col md:justify-between w-full">
            <img
              src="https://storage.googleapis.com/myblankspace-prod/assets/quality-disclaimer.png"
              className="w-25 lg:w-[150px] border border-white border-opacity-10"
              alt=""
            />
            <div className="flex flex-col md:items-end gap-2.5 mt-5 md:mt-0 lg:gap-5">
              <LogoLightFull className="lg:w-auto w-[150px]" />
              <span className=" text-[10px] sm:text-xs md:text-1xs text-white text-opacity-30">
                © 2024 MYBLANKSPACE. ALL RIGHTS RESERVED
              </span>
            </div>
          </div>
        </div>
      </div>
      <AboutUsModal />
      <TermsAndConditionsModal />
      <ContactUsModal />
      <PrivacyPolicyModal />
    </section>
  );
};
export default Footer;

const illustration =
  //prettier-ignore
  <svg width="1220" height="1200" viewBox="0 0 1220 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M790.224 0.112061L948.568 274.373L881.468 458.73L723.123 184.469L790.224 0.112061Z" fill="url(#paint0_linear_2953_20)"/>
<path d="M515.963 158.457L790.224 0.112061L723.123 184.469L448.862 342.813L515.963 158.457Z" fill="url(#paint1_linear_2953_20)"/>
<path d="M948.568 274.372L811.118 353.729L744.018 538.085L881.468 458.729L948.568 274.372Z" fill="url(#paint2_linear_2953_20)"/>
<path d="M811.119 353.729L732.131 216.918L665.031 401.275L744.019 538.086L811.119 353.729Z" fill="url(#paint3_linear_2953_20)"/>
<path d="M732.131 216.918L595.321 295.906L528.22 480.263L665.031 401.275L732.131 216.918Z" fill="url(#paint4_linear_2953_20)"/>
<path d="M595.321 295.906L515.964 158.456L448.863 342.813L528.22 480.263L595.321 295.906Z" fill="url(#paint5_linear_2953_20)"/>
<path d="M860.551 439.35L998.001 359.993L930.901 544.35L793.451 623.707L860.551 439.35Z" fill="url(#paint6_linear_2953_20)"/>
<path d="M998.001 359.993L1156.35 634.254L1089.25 818.611L930.901 544.35L998.001 359.993Z" fill="url(#paint7_linear_2953_20)"/>
<path d="M405.201 222.406L484.558 359.856L417.457 544.212L338.1 406.762L405.201 222.406Z" fill="url(#paint8_linear_2953_20)"/>
<path d="M939.54 576.161L860.552 439.35L793.451 623.707L872.439 760.517L939.54 576.161Z" fill="url(#paint9_linear_2953_20)"/>
<path d="M130.94 380.75L405.201 222.406L338.1 406.762L63.8395 565.107L130.94 380.75Z" fill="url(#paint10_linear_2953_20)"/>
<path d="M484.557 359.855L347.746 438.843L280.646 623.2L417.457 544.212L484.557 359.855Z" fill="url(#paint11_linear_2953_20)"/>
<path d="M802.728 655.147L939.539 576.159L872.439 760.516L735.628 839.504L802.728 655.147Z" fill="url(#paint12_linear_2953_20)"/>
<path d="M1156.35 634.253L882.084 792.598L814.984 976.954L1089.24 818.61L1156.35 634.253Z" fill="url(#paint13_linear_2953_20)"/>
<path d="M347.747 438.843L426.735 575.654L359.635 760.01L280.647 623.2L347.747 438.843Z" fill="url(#paint14_linear_2953_20)"/>
<path d="M882.085 792.598L802.728 655.147L735.628 839.504L814.985 976.954L882.085 792.598Z" fill="url(#paint15_linear_2953_20)"/>
<path d="M289.285 655.01L130.94 380.75L63.8401 565.106L222.185 839.367L289.285 655.01Z" fill="url(#paint16_linear_2953_20)"/>
<path d="M426.735 575.655L289.285 655.011L222.184 839.368L359.634 760.011L426.735 575.655Z" fill="url(#paint17_linear_2953_20)"/>
<path d="M691.966 719.099L771.323 856.549L704.223 1040.91L624.866 903.455L691.966 719.099Z" fill="url(#paint18_linear_2953_20)"/>
<path d="M555.156 798.086L691.966 719.099L624.866 903.455L488.055 982.443L555.156 798.086Z" fill="url(#paint19_linear_2953_20)"/>
<path d="M476.168 661.274L555.156 798.085L488.055 982.442L409.068 845.631L476.168 661.274Z" fill="url(#paint20_linear_2953_20)"/>
<path d="M338.718 740.631L476.168 661.274L409.068 845.631L271.618 924.988L338.718 740.631Z" fill="url(#paint21_linear_2953_20)"/>
<path d="M771.323 856.548L497.062 1014.89L429.962 1199.25L704.222 1040.9L771.323 856.548Z" fill="url(#paint22_linear_2953_20)"/>
<path d="M497.062 1014.89L338.718 740.631L271.617 924.988L429.962 1199.25L497.062 1014.89Z" fill="url(#paint23_linear_2953_20)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M802.728 655.149L939.539 576.161L860.551 439.35L998.001 359.993L1156.35 634.254L882.085 792.599L802.728 655.149Z" fill="url(#paint24_linear_2953_20)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M476.167 661.275L555.155 798.086L691.966 719.098L771.323 856.548L497.062 1014.89L338.717 740.632L476.167 661.275Z" fill="url(#paint25_linear_2953_20)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M484.558 359.856L347.747 438.844L426.735 575.655L289.285 655.012L130.94 380.751L405.201 222.406L484.558 359.856Z" fill="url(#paint26_linear_2953_20)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M811.118 353.73L732.13 216.919L595.32 295.907L515.963 158.457L790.224 0.112061L948.568 274.373L811.118 353.73Z" fill="url(#paint27_linear_2953_20)"/>
<defs>
<linearGradient id="paint0_linear_2953_20" x1="725.147" y1="37.6843" x2="946.545" y2="421.157" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_2953_20" x1="588.016" y1="116.857" x2="651.07" y2="226.069" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_2953_20" x1="814.766" y1="351.622" x2="877.82" y2="460.835" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_2953_20" x1="667.054" y1="254.491" x2="809.096" y2="500.514" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_2953_20" x1="598.649" y1="293.985" x2="661.703" y2="403.197" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_2953_20" x1="450.887" y1="196.029" x2="593.297" y2="442.691" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_2953_20" x1="864.199" y1="437.244" x2="927.253" y2="546.456" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_2953_20" x1="932.924" y1="397.565" x2="1154.32" y2="781.039" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_2953_20" x1="340.124" y1="259.978" x2="482.534" y2="506.64" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_2953_20" x1="795.475" y1="476.922" x2="937.516" y2="722.945" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_2953_20" x1="202.993" y1="339.15" x2="266.047" y2="448.362" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_2953_20" x1="351.075" y1="436.922" x2="414.128" y2="546.134" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_2953_20" x1="806.057" y1="653.226" x2="869.11" y2="762.438" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint13_linear_2953_20" x1="954.138" y1="750.997" x2="1017.19" y2="860.21" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint14_linear_2953_20" x1="282.67" y1="476.415" x2="424.712" y2="722.438" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_2953_20" x1="737.651" y1="692.72" x2="880.062" y2="939.382" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_2953_20" x1="65.8634" y1="418.322" x2="287.262" y2="801.795" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_2953_20" x1="292.933" y1="652.905" x2="355.986" y2="762.117" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint18_linear_2953_20" x1="626.889" y1="756.671" x2="769.3" y2="1003.33" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint19_linear_2953_20" x1="558.484" y1="796.165" x2="621.538" y2="905.377" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint20_linear_2953_20" x1="411.091" y1="698.846" x2="553.132" y2="944.869" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint21_linear_2953_20" x1="342.366" y1="738.525" x2="405.42" y2="847.737" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint22_linear_2953_20" x1="569.115" y1="973.293" x2="632.169" y2="1082.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint23_linear_2953_20" x1="273.641" y1="778.203" x2="495.039" y2="1161.68" gradientUnits="userSpaceOnUse">
<stop stopColor="#2EB39B"/>
<stop offset="0.68" stopColor="#08211C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint24_linear_2953_20" x1="826.704" y1="824.574" x2="460.582" y2="190.431" gradientUnits="userSpaceOnUse">
<stop stopColor="#269682"/>
<stop offset="1" stopColor="#185E51"/>
</linearGradient>
<linearGradient id="paint25_linear_2953_20" x1="826.704" y1="824.574" x2="460.582" y2="190.431" gradientUnits="userSpaceOnUse">
<stop stopColor="#269682"/>
<stop offset="1" stopColor="#185E51"/>
</linearGradient>
<linearGradient id="paint26_linear_2953_20" x1="826.704" y1="824.574" x2="460.582" y2="190.431" gradientUnits="userSpaceOnUse">
<stop stopColor="#269682"/>
<stop offset="1" stopColor="#185E51"/>
</linearGradient>
<linearGradient id="paint27_linear_2953_20" x1="826.704" y1="824.574" x2="460.582" y2="190.431" gradientUnits="userSpaceOnUse">
<stop stopColor="#269682"/>
<stop offset="1" stopColor="#185E51"/>
</linearGradient>
</defs>
</svg>;

export const appLinks = [
  {
    label: "About Us ",
    isExternal: false,
    href: "",
    onClick: () => emit(MODAL_EVENTS.ABOUT_US),
  },
  {
    label: "Privacy Policy",
    isExternal: false,
    href: "",
    onClick: () => emit(MODAL_EVENTS.PRIVACY_POLICY),
  },
  {
    label: "Terms of Service",
    isExternal: false,
    href: "",
    onClick: () => emit(MODAL_EVENTS.TERMS_AND_CONDITIONS),
  },
  {
    label: "Contact Us",
    isExternal: false,
    href: "",
    onClick: () => emit(MODAL_EVENTS.CONTACT_US),
  },
];

export const socialLinks = [
  {
    label: "Instagram",
    isExternal: true,
    href: "https://www.instagram.com/myblankspacehq",
  },
  {
    label: "TikTok",
    isExternal: true,
    href: "https://www.tiktok.com/@myblankspacehq",
  },
  {
    label: "Twitter / X",
    isExternal: true,
    href: "https://twitter.com/myblankspacehq",
  },
  {
    label: "Facebook",
    isExternal: true,
    href: "https://www.facebook.com/myblankspacehq",
  },
];
