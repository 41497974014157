import AppAlertDialog from "@/src/components/custom/alert-dialog";
import { useListener } from "@/src/hooks/useListener";
import { MODAL_EVENTS } from "@/src/lib/constants";
import { useState } from "react";

interface Props {}
const AboutUsModal: React.FC<Props> = ({}) => {
  const [show, setShow] = useState(false);
  useListener(MODAL_EVENTS.ABOUT_US, () => setShow(!show));

  const handleToggle = (state: boolean) => {
    setShow(!show);
  };

  return (
    <AppAlertDialog
      open={show}
      toggle={handleToggle}
      title="About Us"
      content={
        <div className="!text-left">
          {/* <h3 className="font-bold text-lg text-dark-primary">Introduction</h3> */}
          <p className="text-dark-primary">
            Myblankspace is the commercial short-let platform that enables
            Individuals, Businesses and NGOs the flexibility to rent a
            commercial space on a short-term contract at a reasonable rate.
          </p>
          <br />
          <p className="text-dark-primary">
            Our platform is the market place whereby landlords and renters do
            business 24/7. It’s easy to navigate and conduct transactions.
          </p>
          <br />
          <p className="text-dark-primary">
            As a landlord you will be able to list and monitor your commercial
            property booking calendar to ascertain days booked and available
            days.
          </p>
        </div>
      }
      className="max-w-[600px]"
      contentClassName="max-h-[500px] overflow-auto"
      type="info"
      actionText="Continue"
    />
  );
};
export default AboutUsModal;
